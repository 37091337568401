import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface EmailRegistration {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  email: string;
}


@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  getEmails(): Observable<EmailRegistration[]> {
    return this.http.get<EmailRegistration[]>(`${environment.apiBase}/email-registration`);
  }

  deleteEmail(id: string): Observable<EmailRegistration> {
    return this.http.delete<EmailRegistration>(`${environment.apiBase}/email-registration/${id}`);
  }
}
