import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { User, UserService } from '../admin/users/user.service';

export const USER_ID: string = 'userId';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private userService: UserService) { }

  currentUser$: BehaviorSubject<User | null | undefined> = new BehaviorSubject<
    User | null | undefined
  >(undefined);

  login(email: string, password: string): Observable<User> {
    return this.http
      .post(
        `${environment.apiBase}/auth/signin`,
        { email: email, password: password }
      )
      .pipe(
        switchMap(() => this.userService.getMe()),
        tap((user) => {
          this.currentUser$.next(user);
          localStorage.setItem('UID', user.id);
        }),
      );
  }

  logout(): Observable<boolean> {
    this.currentUser$.next(null);
    localStorage.removeItem('UID');
    return this.http
      .get<boolean>(`${environment.apiBase}/auth/signout`);
  }

  refreshToken(): Observable<boolean> {
    return this.http
      .get<boolean>(`${environment.apiBase}/auth/refresh`);
  }

  isLoggedIn(): boolean {
    const userId = localStorage.getItem('UID');
    if (userId) {
      return true;
    }
    return false;
  }

  async isAdmin(): Promise<boolean> {
    const user = await firstValueFrom(this.currentUser$);
    return user?.role === 'ADMIN';
  }
}
