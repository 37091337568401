<nav class="bg- fixed inset-x-0 top-0 z-50 bg-white shadow-lg shadow-b-2.5 -shadow-spread-1">
  <div class="mx-auto pl-3 pr-5 md:px-8 lg:px-12 xl:container">
    <div class="flex h-16 justify-between md:h-20 lg:h-24">
      <div class="flex space-x-4">
        <a
          routerLink="/"
          class="flex items-center py-5 px-2 text-stone-950 text-2xl md:text-3xl lg:text-4xl font-extralight"
        >
          <img
            src="assets/elysator_logo.png"
            class="w-28 md:w-40 lg:w-48"
          >
        </a>
      </div>

      <div class="hidden items-center space-x-5 text-gray-700 text-md md:text-md lg:text-lg font-normal md:flex">
        <a
          routerLink="/app"
          routerLinkActive="text-elysator-blue"
          class="py-5 px-4 lg:px-5"
        >{{ 'menu.app' | translate }}</a>
        <a
          routerLink="/support"
          routerLinkActive="text-elysator-blue"
          class="py-5 px-4 lg:px-5"
        >{{ 'menu.support' | translate }}</a>
        <a
          routerLink="/faq"
          routerLinkActive="text-elysator-blue"
          class="py-5 px-4 lg:px-5"
        >{{ 'menu.faq' | translate }}</a>

        <div class="m-1 hs-dropdown [--trigger:hover] relative inline-flex">
          <span
            id="hs-dropdown-hover-event"
            class="py-5 px-4 lg:px-5 hs-dropdown-toggle inline-flex items-center gap-x-2 disabled:opacity-50 disabled:pointer-events-none cursor-pointer"
          ><img
              [src]="'assets/' + selectedLanguage + '.svg'"
              height="24"
              class="me-1"
            > {{ selectedLanguage | uppercase }} <svg
              class="hs-dropdown-open:rotate-180 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m6 9 6 6 6-6" />
            </svg></span>

          <div
            class="border-t-2 border-gray-100 hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full"
            aria-labelledby="hs-dropdown-hover-event"
          >
            <a
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-base text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              href="#"
              (click)="onLanguageChange('de')"
            >
              <img
                src="assets/de.svg"
                height="24"
                class="me-1"
              > Deutsch
            </a>
            <a
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-base text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              href="#"
              (click)="onLanguageChange('en')"
            >
              <img
                src="assets/en.svg"
                height="24"
                class="me-1"
              > English
            </a>
            <a
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-base text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              href="#"
              (click)="onLanguageChange('fr')"
            >
              <img
                src="assets/fr.svg"
                height="24"
                class="me-1"
              > Français
            </a>
            <a
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-base text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              href="#"
              (click)="onLanguageChange('hr')"
            >
              <img
                src="assets/hr.svg"
                height="24"
                class="me-1"
              > Hrvatski
            </a>
          </div>
        </div>

        <button
          type="button"
          routerLink="/admin"
          class="py-1 px-4 inline-flex items-center gap-x-2 font-normal rounded-lg border border-transparent bg-elysator-blue text-white hover:opacity-95 disabled:opacity-50 disabled:pointer-events-none"
        >{{ 'menu.account' | translate }}</button>
      </div>

      <div class="flex items-center md:hidden">
        <button
          class="fill-curren outline-none focus:outline-none active:outline-none"
          (click)="navOpen = !navOpen"
        >
          <ng-container *ngIf="navOpen">
            <svg
              class="h-6 w-6 fill-stone-950"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z">
              </path>
            </svg>
          </ng-container>
          <ng-container *ngIf="!navOpen">
            <svg
              class="h-6 w-6 fill-stone-950"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21 18H3V16H21V18ZM21 13H3V11H21V13ZM21 8H12V6H21V8Z"></path>
            </svg>
          </ng-container>
        </button>
      </div>
    </div>
  </div>

  <div
    class="top-15 fixed left-0 flex w-full flex-col content-center justify-center bg-white text-gray-800 md:hidden shadow-lg shadow-b-2.5 -shadow-spread-1 pb-2"
    [ngClass]="navOpen ? '' : 'hidden'"
  >
    <a
      routerLink="/app"
      routerLinkActive="text-elysator-blue"
      class="m-2 block p-3 text-center text-lg hover:bg-gray1 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
    >{{ 'menu.app' | translate }}</a>
    <a
      routerLink="/support"
      routerLinkActive="text-elysator-blue"
      class="m-2 block p-3 text-center text-lg hover:bg-gray1 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
    >{{ 'menu.support' | translate }}</a>
    <a
      routerLink="/faq"
      routerLinkActive="text-elysator-blue"
      class="m-2 block p-3 text-center text-lg hover:bg-gray1 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
    >{{ 'menu.faq' | translate }}</a>

    <div class="hs-dropdown [--strategy:static] [--adaptive:none] m-2 block p-3 text-center text-lg hover:bg-gray1">
      <span
        id="hs-mega-menu-basic-dr"
        class="flex items-center justify-center w-full cursor-pointer p-3 text-lg hover:bg-gray1 rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
      >
        <img
          [src]="'assets/' + selectedLanguage + '.svg'"
          height="24"
          class="me-2"
        > {{ selectedLanguage | uppercase }}
        <svg
          class="ms-1 flex-shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </span>

      <div
        class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100 opacity-0 z-10 bg-gray-50 p-2 before:absolute top-full before:-top-5 before:start-0 before:w-full before:h-5 hidden"
      >
        <a
          class="flex items-center justify-center m-2 p-3 text-center text-base hover:bg-gray1 rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          href="#"
          (click)="onLanguageChange('de')"
        >
          <img
            src="assets/de.svg"
            height="24"
            class="me-2"
          > Deutsch
        </a>
        <a
          class="flex items-center justify-center m-2 p-3 text-center text-base hover:bg-gray1 rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          href="#"
          (click)="onLanguageChange('en')"
        >
          <img
            src="assets/en.svg"
            height="24"
            class="me-2"
          > English
        </a>
        <a
          class="flex items-center justify-center m-2 p-3 text-center text-base hover:bg-gray1 rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          href="#"
          (click)="onLanguageChange('fr')"
        >
          <img
            src="assets/fr.svg"
            height="24"
            class="me-2"
          > Français
        </a>
        <a
          class="flex items-center justify-center m-2 p-3 text-center text-base hover:bg-gray1 rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          href="#"
          (click)="onLanguageChange('hr')"
        >
          <img
            src="assets/hr.svg"
            height="24"
            class="me-2"
          > Hrvatski
        </a>
      </div>
    </div>

    <button
      routerLink="/admin"
      type="button"
      class="my-2 mx-auto md:mx-2 p-3 justify-center text-lg py-1 px-4 flex items-center gap-x-2 text-md md:text-lg lg:text-xl font-normal rounded-lg border border-transparent bg-elysator-blue text-white hover:opacity-95 disabled:opacity-50 disabled:pointer-events-none"
    >{{ 'menu.account' | translate }}</button>
  </div>
</nav>
