<app-page-wrapper>
  <h1 class="block text-3xl mb-9 font-bold text-gray-800 sm:text-4xl lg:leading-tight">{{ 'dataProtection.title' | translate }}</h1>
  <div class="text-lg text-gray-800 mb-10 md:mb-16">
    <p class="mt-3 mb-5">{{ 'dataProtection.text' | translate }}</p>
    <h3 class="text-2xl font-bold mb-3">Name und Anschrift des Verantwortlichen</h3>
    <p class="mb-3">Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), lautet:
    </p>
    <p class="mb-5">
      <b>ELYSATOR Engineering GmbH</b><br />
      Rauheckstr. 20<br />
      74232 Abstatt<br />
      {{ 'legalNotice.phone' | translate }}: <a
        href="tel:+49 7062 9795765"
        class="text-elysator-blue hover:underline"
      >+49 7062 9795765</a><br />
      {{ 'legalNotice.fax' | translate }}: +49 7062 9795767<br />
      {{ 'legalNotice.email' | translate }}: <a
        href="mailto:info&commat;elysator.de"
        class="text-elysator-blue hover:underline"
      >info&commat;elysator.de</a>
    </p>
    <h3 class="text-2xl font-bold mb-3">Welche personenbezogenen Daten wir sammeln und warum wir sie sammeln</h3>
    <p class="mb-3">Wenn Sie auf diese Webseite zugreifen, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
      werden im
      Server-Logfile erfasst und beinhalten die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
      Internet-Service-Providers, Ihre IPAdresse und ähnliches.</p>
    <p class="mb-3">Dies aus folgendem Grund:</p>
    <ul class="list-disc list-outside ms-5 mb-3">
      <li class="mb-2">Sicherstellung eines problemlosen Verbindungsaufbaus der Website</li>
      <li class="mb-2">Sicherstellung einer reibungslosen Nutzung unserer Website</li>
      <li class="mb-2">Auswertung der Systemsicherheit und - stabilität sowie</li>
      <li class="mb-2">zu weiteren administrativen Zwecken</li>
    </ul>
    <p class="mb-5">Ihre Daten werden nicht verwendet, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden lediglich
      statistisch
      ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>
    <h3 class="text-2xl font-bold mb-3">Speicherdauer</h3>
    <p class="mb-5">Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten,
      die der Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist.</p>
    <h3 class="text-2xl font-bold mb-3">SSL-Verschlüsselung</h3>
    <p class="mb-10">Um die Sicherheit Ihrer Daten bei der Übertragung zu schätzen, verwenden wir dem aktuellen Stand der Technik
      entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
    <h1 class="text-3xl font-bold mb-3">ELYSATOR APP</h1>
    <h3 class="text-2xl font-bold mb-3">Beschreibung und Umfang der Datenverarbeitung</h3>
    <p class="mb-3">Wassermessungen können mit einem Wassermessgerät durchgeführt werden. Um aktuelle Gerätedaten abrufen zu können, muss
      das Gerät mit der Cloud verbunden werden und die passende ELYSATOR App zum Produkt heruntergeladen werden. Um die Dienste der App
      (z.B. Messwerte und Testmessungen) nutzen zu können, müssen Sie die ELYSATOR App herunterladen.</p>
    <p class="mb-3">Folgende Daten werden bei der Nutzung der App erhoben und gespeichert:</p>
    <ul class="list-decimal list-outside ms-5 mb-5">
      <li>Informationen über den Browsertyp und die verwendete Version</li>
      <li>Das Betriebssystem des Nutzers</li>
      <li>Den Internet-Service-Provider des Nutzers</li>
      <li>Die IP-Adresse des Nutzers</li>
      <li>Verbrauchsdaten</li>
    </ul>
    <h3 class="text-2xl font-bold mb-3">Rechtsgrundlage für die Datenverarbeitung</h3>
    <p class="mb-5">Rechtsgrundlage für die Verarbeitung der Daten ist eine Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.</p>
    <h3 class="text-2xl font-bold mb-3">Zweck der Datenverarbeitung</h3>
    <p class="mb-5">Diese Daten werden verarbeitet und gespeichert, um eine Anzeige der Daten (z.B. aktuelle Verbrauchsdaten) in der App zu ermöglichen. Die Übertragung der Daten erfolgt verschlüsselt. Die Daten werden nicht an Dritte weitergegeben.</p>
    <h3 class="text-2xl font-bold mb-3">Dauer der Speicherung</h3>
    <p class="mb-5">Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.</p>
    <h3 class="text-2xl font-bold mb-3">Widerspruchs- und Beseitigungsmöglichkeit</h3>
    <p class="mb-10">Der Nutzer hat jederzeit die Möglichkeit seine Einwilligung zur Verarbeitung der personenbezogenen Daten mit Wirkung für die Zukunft zu widerrufen. In einem solchen Fall kann die Nutzung der App nicht fortgeführt werden.</p>
    <h1 class="text-3xl font-bold mb-3">Änderungen</h1>
    <p>Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung.</p>
  </div>
</app-page-wrapper>
