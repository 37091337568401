import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';
import { LicenseCodeService } from '../license-codes/license-code.service';
import { Role, User, UserService } from './user.service';

@Component({
  selector: 'app-users-page',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule],
  templateUrl: './users-page.component.html',
  styleUrl: './users-page.component.scss',
})
export class UsersPageComponent {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private licenseCodeService: LicenseCodeService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  public users: User[] = [];
  public Role: typeof Role = Role;
  public currentUserId?: string;
  public userId?: string;
  public textFilter?: string;

  public isAdmin = false;

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(user => {
      this.isAdmin = user?.role === 'ADMIN';
      this.currentUserId = user?.id;
      this.reload();
    });
  }

  public getCurrentUserId() {
    return localStorage.getItem('UID');
  }

  public sendLicenseCode() {
    if (this.isAdmin && this.userId) {
      this.licenseCodeService.createLicenseCodeEmail(this.userId).subscribe({
        next: (result) => this.toastr.success('Lizenz-Code erfolgreich versendet'),
        error: (error) => this.toastr.error('Lizenz-Code Versand fehlgeschlagen', 'Fehler!'),
      });
    }
  }

  public deleteUser() {
    if (this.isAdmin && this.userId) {
      this.userService.deleteUser(this.userId).subscribe({
        next: (user) => {
          this.toastr.success('Benutzer erfolgreich gelöscht');
          this.users = this.users.filter(u => u.id !== user.id);
          this.userId = undefined;
        },
        error: (error) => this.toastr.error('Benutzer konnte nicht gelöscht werden', 'Fehler!'),
      });
    }
  }

  public userDetails(user: User) {
    if (this.isAdmin || this.currentUserId === user.id) {
      this.router.navigateByUrl(`/admin/users/${user.id}`);
    }
  }

  private loadData() {
    if (this.isAdmin) {
      this.userService.getUsers().subscribe({
        next: (users) => {
          if (this.textFilter && this.textFilter.length > 2) {
            this.users = users.filter(u => u.name.toLowerCase().includes(this.textFilter!.toLowerCase())
              || u.email.toLowerCase().includes(this.textFilter!.toLowerCase())
              || u.role.toLowerCase().includes(this.textFilter!.toLowerCase())
            );
          } else {
            this.users = users;
          }
        },
        error: (error) => this.toastr.error('Benutzer konnten nicht erfolgreich geladen werden', 'Fehler!'),
      });
    }
  }

  public reload() {
    this.loadData();
  }
}

