import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { EmailRegistrationService } from './email-registration.service';

@Component({
  selector: 'app-email-registration',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule],
  templateUrl: './email-registration.component.html',
  styleUrl: './email-registration.component.scss'
})
export class EmailRegistrationComponent {

  constructor(
    private fb: FormBuilder,
    private emailRegistrationService: EmailRegistrationService,
  ) { }


  form = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    termsAndconditions: [false, Validators.requiredTrue],
  });

  public error?: string;
  public success?: string;

  onSubmit() {
    this.error = undefined;
    this.success = undefined;
    const { email, termsAndconditions } = this.form.getRawValue();
    const emailValid = this.form.get('email')?.valid ?? false;
    const termsAndConditionsValid = this.form.get('termsAndconditions')?.valid ?? false;
    if (!emailValid && !termsAndConditionsValid) {
      this.error = 'Bitte geben Sie eine gültige E-Mail Adresse ein und bestätigen Sie, dass Sie die Teilnahmebedingungen und Hinweise zum Datenschutz gelesen haben';
    } else if (!emailValid) {
      this.error = 'Bitte geben Sie eine gültige E-Mail Adresse ein';
    } else if (!termsAndConditionsValid) {
      this.error = 'Bitte bestätigen Sie, dass Sie die Teilnahmebedingungen und Hinweise zum Datenschutz gelesen haben';
    }

    if (!this.form.valid) {
      return;
    }
    this.emailRegistrationService.register(email).subscribe({
      next: (result) => {
        this.success = 'Ihre E-Mail Adresse wurde für das Gewinnspiel registriert';
        this.form.reset();
      },
      error: (err) => {
        console.log(err);
        this.error = 'Gewinnspiel Registrierung fehlgeschlagen';
      },
    });
  }
}
