import { Component } from '@angular/core';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IStaticMethods } from 'preline/preline';
import { environment } from '../environments/environment';

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TranslateModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = `elysator-web | ${environment.name}`;

  constructor(
    private router: Router, private translateService: TranslateService) {
  }

  ngOnInit() {

    const storedLang = localStorage.getItem('language');
    const browserLang = this.translateService.getBrowserLang();

    this.translateService.use(storedLang ?? browserLang ?? 'en');

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 100);
      }
      // this.userService.getMe().subscribe({
      //   next: (user) => {
      //     this.authService.currentUser$.next(user);
      //   },
      //   error: (error) => {
      //     console.log(error);
      //     this.authService.currentUser$.next(null);
      //   },
      // });
    });
  }
}
