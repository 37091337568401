import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PageWrapperComponent } from '../page-wrapper/page-wrapper.component';

@Component({
  selector: 'app-legal-notice-page',
  standalone: true,
  imports: [PageWrapperComponent, TranslateModule],
  templateUrl: './legal-notice-page.component.html',
  styleUrl: './legal-notice-page.component.scss'
})
export class LegalNoticePageComponent {

}
