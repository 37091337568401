<footer class="bg-gray-300 py-8">
  <div class="mx-auto flex h-full flex-wrap content-center px-0 sm:px-2 md:px-3 lg:px-8 xl:container">
    <span class="mx-auto px-3 mb-8 sm:mr-auto sm:ml-1 sm:mb-0 text-gray-500">
      &copy; 2024 Elysator Engineering GmbH
    </span>
    <span class="mx-auto sm:ml-auto sm:mr-2 text-gray-500">
      <a
        routerLink="/legal-notice"
        class="px-3 md:px-4 lg:px-5"
      >{{ 'menu.legalNotice' | translate }}</a>
      <a
        routerLink="/data-protection"
        class="px-3 md:px-4 lg:px-5"
      >{{ 'menu.dataProtection' | translate }}</a>
    </span>
  </div>
</footer>
