@if (isAdmin) {
<h1 class="text-xl">Übersicht</h1>
<div class="max-w-5xl pb-10 pt-4 lg:pb-14 lg:pt-8">
  <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-6">
    <a
      class="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      [routerLink]="'/admin/users'"
    >
      <div class="p-4 md:p-5">
        <div class="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path
              d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"
            />
          </svg>

          <div class="grow ms-5">
            <h3 class="group-hover:text-elysator-blue font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
              {{ usersCount }} Benutzer
            </h3>
            <p class="text-sm text-gray-500">
              {{ usersActiveCount }} aktiv, {{ usersCount - usersActiveCount }} inaktiv
            </p>
          </div>
        </div>
      </div>
    </a>

    <a
      class="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      [routerLink]="'/admin/license-codes'"
    >
      <div class="p-4 md:p-5">
        <div class="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path
              d="M480-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z"
            />
          </svg>

          <div class="grow ms-5">
            <h3 class="group-hover:text-elysator-blue font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
              @if (licenseCodesCount === 1) {
              {{ licenseCodesCount }} Lizenz-Code
              }
              @else {
              {{ licenseCodesCount }} Lizenz-Codes
              }
            </h3>
            <p class="text-sm text-gray-500">
              {{ licenseCodesRedeemedCount }} eingelöst, {{ licenseCodesCount - licenseCodesRedeemedCount }} frei
            </p>
          </div>
        </div>
      </div>
    </a>

    <a
      class="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      [routerLink]="'/admin/emails'"
    >
      <div class="p-4 md:p-5">
        <div class="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path
              d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"
            />
          </svg>
          <div class="grow ms-5">
            <h3 class="group-hover:text-elysator-blue font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
              @if (emailsCount === 1) {
              {{ emailsCount }} E-Mail Adresse
              }
              @else {
              {{ emailsCount }} E-Mail Adressen
              }
            </h3>
            <p class="text-sm text-gray-500">

            </p>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
}
