import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  public navOpen: boolean = false;
  public selectedLanguage: string = 'en';

  constructor(
    private translateService: TranslateService,
  ) {
    this.selectedLanguage = this.translateService.currentLang;
  }

  public onLanguageChange(lang: string) {
    if (!['de', 'en', 'fr', 'hr'].includes(lang)) {
      lang = 'en';
    }
    this.selectedLanguage = lang;
    this.translateService.use(this.selectedLanguage);
    localStorage.setItem('language', this.selectedLanguage);
  }
}
