import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError, switchMap, throwError } from "rxjs";
import { AuthService } from "./auth.service";

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  var isRefreshing = false;

  req = req.clone({
    withCredentials: true,
  });

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error instanceof HttpErrorResponse &&
        !req.url.includes('auth/refresh') &&
        error.status === 401
      ) {
        return authService.refreshToken().pipe(
          switchMap((res) => {
            console.log('token refreshed');
            return next(req);
          }),
          catchError((error) => {
            if (error.status == 401 || error.status == 403) {
              console.log('refresh token missing or expired. redirecting to logout...')
              localStorage.removeItem('UID');
              authService.currentUser$.next(null)
              router.navigateByUrl('/login');
              return throwError(() => new Error('token refresh failed'));
            }
            return throwError(() => error);
          })
        );
      }
      return throwError(() => error);
    })
  );
};
