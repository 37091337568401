import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface LicenseCode {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  code: string;
  userId: string;
  userEmail: string;
}

@Injectable({
  providedIn: 'root'
})
export class LicenseCodeService {
  constructor(private http: HttpClient) { }

  getLicenseCodes(): Observable<LicenseCode[]> {
    return this.http.get<LicenseCode[]>(`${environment.apiBase}/license-code`);
  }

  createLicenseCodePdf(quantity: number = 1): Observable<Blob> {
    return this.http.post<Blob>(`${environment.apiBase}/license-code/generate/pdf?quantity=${quantity}`, {}, { responseType: 'blob' as 'json' })
      .pipe(map(res => new Blob([res], { type: 'application/pdf' })));;
  }

  createLicenseCodeEmail(userId: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiBase}/license-code/send/user/${userId}`, {});
  }

  deleteLicenseCode(id: string): Observable<LicenseCode> {
    return this.http.delete<LicenseCode>(`${environment.apiBase}/license-code/${id}`);
  }
}

