import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmailService } from '../emails/email.service';
import { LicenseCodeService } from '../license-codes/license-code.service';
import { UserService } from '../users/user.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-overview-page',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './overview-page.component.html',
  styleUrl: './overview-page.component.scss',
})
export class OverviewPageComponent implements OnInit {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private licenseCodeService: LicenseCodeService,
    private emailService: EmailService,
    private toastr: ToastrService,
  ) { }

  public usersCount: number = 0;
  public usersActiveCount: number = 0;

  public licenseCodesCount: number = 0;
  public licenseCodesRedeemedCount: number = 0;

  public emailsCount: number = 0;

  public isAdmin = false;

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(user => {
      this.isAdmin = user?.role === 'ADMIN';
      this.reload();
    });
  }

  private loadData() {
    if (this.isAdmin) {
      this.userService.getUsers().subscribe({
        next: (users) => {
          this.usersCount = users.length;
          this.usersActiveCount = users.filter(u => u.active === true).length;
        },
        error: (error) => this.toastr.error('Benutzer konnten nicht erfolgreich geladen werden', 'Fehler!'),
      });

      this.licenseCodeService.getLicenseCodes().subscribe({
        next: (licenseCodes) => {
          this.licenseCodesCount = licenseCodes.length;
          this.licenseCodesRedeemedCount = licenseCodes.filter(lc => !!lc.userId).length;
        },
        error: (error) => this.toastr.error('Lizenz-Codes konnten nicht erfolgreich geladen werden', 'Fehler!'),
      });

      this.emailService.getEmails().subscribe({
        next: (emails) => {
          this.emailsCount = emails.length
        },
        error: (error) => this.toastr.error('E-Mail Adressen konnten nicht erfolgreich geladen werden', 'Fehler!'),
      });
    }
  }

  public reload() {
    this.loadData();
  }
}
