import { Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { EmailsPageComponent } from './admin/emails/emails-page.component';
import { LicenseCodesPageComponent } from './admin/license-codes/license-codes-page.component';
import { OverviewPageComponent } from './admin/overview/overview-page.component';
import { SidebarComponent } from './admin/sidebar/sidebar.component';
import { UserPageComponent } from './admin/users/user/user-page.component';
import { UsersPageComponent } from './admin/users/users-page.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { PasswordForgottenComponent } from './auth/password-forgotten/password-forgotten.component';
import { DataProtectionPageComponent } from './data-protection/data-protection-page.component';
import { EmailRegistrationComponent } from './email-registration/email-registration.component';
import { FaqPageComponent } from './faq/faq-page.component';
import { HomePageComponent } from './home/home-page.component';
import { LegalNoticePageComponent } from './legal-notice/legal-notice-page.component';
import { SupportPageComponent } from './support/support-page.component';

export const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: 'admin', component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      {
        path: 'overview',
        children: [
          { path: '', pathMatch: 'full', component: SidebarComponent, outlet: 'sidebar' },
          { path: '', pathMatch: 'full', component: OverviewPageComponent, outlet: 'content' },
        ]
      },
      {
        path: 'users',
        children: [
          { path: '', pathMatch: 'full', component: SidebarComponent, outlet: 'sidebar' },
          {
            path: '', component: UsersPageComponent, outlet: 'content'
          },
        ]
      },
      {
        path: 'users/:userId',
        children: [
          { path: '', pathMatch: 'full', component: SidebarComponent, outlet: 'sidebar' },
          {
            path: '', component: UserPageComponent, outlet: 'content'
          },
        ]
      },
      {
        path: 'license-codes',
        children: [
          { path: '', pathMatch: 'full', component: SidebarComponent, outlet: 'sidebar' },
          { path: '', component: LicenseCodesPageComponent, outlet: 'content' },
        ]
      },
      {
        path: 'emails',
        children: [
          { path: '', pathMatch: 'full', component: SidebarComponent, outlet: 'sidebar' },
          { path: '', component: EmailsPageComponent, outlet: 'content' },
        ]
      },
    ]
  },
  { path: 'app', component: HomePageComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', redirectTo: 'login' },
  { path: 'password-forgotten', component: PasswordForgottenComponent },
  { path: 'quiz', component: EmailRegistrationComponent },
  { path: 'legal-notice', component: LegalNoticePageComponent },
  { path: 'data-protection', component: DataProtectionPageComponent },
  { path: 'support', component: SupportPageComponent },
  { path: 'faq', component: FaqPageComponent },
  { path: '**', redirectTo: '' }
];
