@if (isAdmin) {
<div class="flex flex-col group p-3 bg-white border shadow-sm rounded-xl">
  <div class="ps-3 pe-1 pb-4 pt-1 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200">
    <h1 class="text-xl">Lizenz-Codes</h1>
    <!-- Input -->
    <!-- <div class="sm:col-span-1">

  </div> -->
    <!-- End Input -->

    <div class="sm:col-span-2 md:grow">
      <div class="flex justify-end gap-x-3">
        <div class="relative">
          <input
            type="text"
            id="hs-as-table-product-review-search"
            name="hs-as-table-product-review-search"
            class="py-2.5 px-3 ps-11 block w-full border-gray-200 rounded-lg text-sm focus:border-elysator-blue focus:ring-elysator-blue disabled:opacity-50 disabled:pointer-events-none"
            placeholder="Suchen"
            [(ngModel)]="textFilter"
            (input)="reload()"
          >
          <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4">
            <svg
              class="text-gray-400 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path
                d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"
              />
            </svg>
          </div>
        </div>

        <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
          <button
            id="hs-as-table-table-export-dropdown"
            type="button"
            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            title="Pdf-Datei mit neuen Lizenz-Codes erstellen"
            data-hs-overlay="#license-creation-dialog"
          >
            <svg
              class="text-elysator-blue fill-current"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path
                d="M480-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z"
              />
            </svg>
            Lizenzen erstellen
          </button>
        </div>

        <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
          <button
            id="hs-as-table-table-export-dropdown"
            type="button"
            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            (click)="reload()"
            title="Ansicht aktualisieren"
          >
            <svg
              class="text-elysator-blue fill-current"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path
                d="M522-80v-82q34-5 66.5-18t61.5-34l56 58q-42 32-88 51.5T522-80Zm-80 0Q304-98 213-199.5T122-438q0-75 28.5-140.5t77-114q48.5-48.5 114-77T482-798h6l-62-62 56-58 160 160-160 160-56-56 64-64h-8q-117 0-198.5 81.5T202-438q0 104 68 182.5T442-162v82Zm322-134-58-56q21-29 34-61.5t18-66.5h82q-5 50-24.5 96T764-214Zm76-264h-82q-5-34-18-66.5T706-606l58-56q32 39 51 86t25 98Z"
              />
            </svg>
          </button>
        </div>

        <!-- <div
          class="hs-dropdown relative inline-block [--placement:bottom-right]"
          data-hs-dropdown-auto-close="inside"
        >
          <button
            id="hs-as-table-table-filter-dropdown"
            type="button"
            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          >
            <svg
              class="flex-shrink-0 size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M3 6h18" />
              <path d="M7 12h10" />
              <path d="M10 18h4" />
            </svg>
            Filter
            <span class="ps-2 text-xs font-semibold text-elysator-blue border-s border-gray-200">
              1
            </span>
          </button>
          <div
            class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-48 z-10 bg-white shadow-md rounded-lg mt-2"
            aria-labelledby="hs-as-table-table-filter-dropdown"
          >
            <div class="divide-y divide-gray-200">
              <label
                for="hs-as-filters-dropdown-all"
                class="flex py-2.5 px-3"
              >
                <input
                  type="checkbox"
                  class="shrink-0 mt-0.5 border-gray-300 rounded text-elysator-blue focus:ring-elysator-blue disabled:opacity-50 disabled:pointer-events-none"
                  id="hs-as-filters-dropdown-all"
                  checked
                >
                <span class="ms-3 text-sm text-gray-800">All</span>
              </label>
              <label
                for="hs-as-filters-dropdown-published"
                class="flex py-2.5 px-3"
              >
                <input
                  type="checkbox"
                  class="shrink-0 mt-0.5 border-gray-300 rounded text-elysator-blue focus:ring-elysator-blue disabled:opacity-50 disabled:pointer-events-none"
                  id="hs-as-filters-dropdown-published"
                >
                <span class="ms-3 text-sm text-gray-800">Published</span>
              </label>
              <label
                for="hs-as-filters-dropdown-pending"
                class="flex py-2.5 px-3"
              >
                <input
                  type="checkbox"
                  class="shrink-0 mt-0.5 border-gray-300 rounded text-elysator-blue focus:ring-elysator-blue disabled:opacity-50 disabled:pointer-events-none"
                  id="hs-as-filters-dropdown-pending"
                >
                <span class="ms-3 text-sm text-gray-800">Pending</span>
              </label>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- End Header -->
  <div class="-m-1.5 overflow-x-auto">
    <div class="p-1.5 min-w-full inline-block align-middle">
      <div class="overflow-hidden">
        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
              >Code</th>
              <th
                scope="col"
                class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
              >Erstellt</th>
              <th
                scope="col"
                class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
              >Benutzer</th>
              <th
                scope="col"
                class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
              >Eingelöst</th>
              <th
                scope="col"
                class="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
              ></th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr *ngFor="let lc of licenseCodes; index as i;">
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 font-mono">{{ lc.code }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{{ lc.createdAt | date:'dd.MM.yyyy - HH:mm'
                }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{{ lc.userEmail }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{{ lc.userEmail ? (lc.updatedAt |
                date:'dd.MM.yyyy - HH:mm') : ''}}</td>
              <td class="px-2 py-4 whitespace-nowrap text-end text-sm font-medium">
                <button
                  type="button"
                  title="Lizenz-Code löschen"
                  (click)="licenseCodeId = lc.id"
                  data-hs-overlay="#license-delete-dialog"
                ><svg
                    class="text-elysator-red fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path
                      d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
                    />
                  </svg></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div
  id="license-creation-dialog"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto"
  >
    <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          Lizenzen erstellen
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#license-creation-dialog"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="p-4 overflow-y-auto">
        <label
          for="input-label"
          class="block text-sm font-medium mb-2"
        >Anzahl (max. 1000)</label>
        <input
          id="licensesToCreate"
          type="text"
          [maxlength]="4"
          [(ngModel)]="licensesToCreate"
          required
          class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-elysator-blue focus:ring-elysator-blue"
          autofocus=""
        >
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#license-creation-dialog"
        >
          Abbrechen
        </button>
        <button
          type="button"
          (click)="createLicenseCodePdf(licensesToCreate)"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-elysator-blue text-white hover:opacity-95 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#license-creation-dialog"
        >
          Lizenzen erstellen
        </button>
      </div>
    </div>
  </div>
</div>

<div
  id="license-delete-dialog"
  class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
>
  <div class="sm:max-w-lg sm:w-full m-3 sm:mx-auto">
    <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
      <div class="p-4 overflow-y-auto">
        <p class="mt-1 text-gray-800">
          Lizenz-Code löschen?
        </p>
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#license-delete-dialog"
        >
          Abbrechen
        </button>
        <button
          (click)="deleteLicenseCode()"
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-elysator-red text-white hover:opacity-90 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#license-delete-dialog"
        >
          Löschen
        </button>
      </div>
    </div>
  </div>
</div>
}
