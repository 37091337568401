import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../auth/auth.service';
import { MeasuredObject, User, UserService } from '../user.service';

@Component({
  selector: 'app-user-page',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './user-page.component.html',
  styleUrl: './user-page.component.scss'
})
export class UserPageComponent implements OnInit {

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public currentUserId?: string;
  public isAdmin = false;

  public user?: User;
  public measuredObjects: MeasuredObject[] = [];

  @Input()
  set userId(userId: string) {
    console.log('id: ', userId)
    this.reload();
  }

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(user => {
      this.isAdmin = user?.role === 'ADMIN';
      this.currentUserId = user?.id;
    });
  }

  public getCurrentUserId() {
    return localStorage.getItem('UID');
  }

  private loadData() {
    if (this.isAdmin || this.userId === this.currentUserId) {
      this.userService.getUser(this.userId).subscribe({
        next: (user) => {
          this.user = user;
          this.userService.getMeasuredObjectsByUser(this.userId).subscribe(measuredObjects => {
            this.measuredObjects = measuredObjects;
          });
        },
        error: (error) => this.toastr.error('Benutzer konnte nicht erfolgreich geladen werden', 'Fehler!'),
      });
    }
  }

  public reload() {
    this.loadData();
  }
}
