<div class="relative bg-gradient-to-bl from-blue-100 via-transparent dark:from-blue-950 dark:via-transparent">

  <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-24">

    <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-5 lg:py-8">
      <div class="max-w-2xl text-center mx-auto">
        <p class="inline-block mb-10">
          <img
            src="assets/elysator_logo.png"
            width="260"
            alt="Elysator"
          />
        </p>

        <div class="mt-5 max-w-2xl">
          <h1 class="block font-semibold text-elysator-blue text-3xl md:text-4xl lg:text-5xl dark:text-gray-200">
            Mitmachen und gewinnen!
          </h1>
        </div>

        <div class="mt-5 max-w-3xl">
          <p class="text-lg text-gray-600 dark:text-gray-400">Sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
            magna aliquyam erat, sed diam voluptua.</p>
        </div>

        <div class="mt-7 sm:mt-12 mx-auto max-w-xl relative">
          <form
            (ngSubmit)="onSubmit()"
            [formGroup]="form"
          >
            <div
              class="mx-auto max-w-2xl sm:flex sm:space-x-3 p-3 bg-white border rounded-lg shadow-lg shadow-gray-100 dark:bg-slate-900 dark:border-gray-700 dark:shadow-gray-900/[.2]"
            >
              <div class="flex-[1_0_0%]">
                <label
                  for="hs-search-article-1"
                  class="block text-sm text-gray-700 font-medium dark:text-white"
                ><span class="sr-only">E-Mail Adresse</span></label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  formControlName="email"
                  autocomplete="email"
                  class="py-2.5 px-4 block w-full border-transparent rounded-lg focus:border-elysator-blue focus:ring-elysator-blue dark:bg-slate-900 dark:border-transparent dark:text-gray-400 dark:focus:ring-gray-600"
                  placeholder="E-Mail Adresse"
                  required
                  aria-describedby="email-error"
                >
              </div>
              <div class="pt-2 sm:pt-0 grid sm:block sm:flex-[0_0_auto]">
                <button
                  type="submit"
                  class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-elysator-blue text-white hover:opacity-95 disabled:opacity-50 disabled:pointer-events-none"
                >Teilnehmen</button>
              </div>
            </div>

            <p
              [ngClass]="!error ? 'hidden' : ''"
              class="text-sm text-center text-elysator-red mt-2 ms-3 me-3"
              id="email-registration-error"
            >{{ error }}</p>

            <p
              [ngClass]="!success ? 'hidden' : ''"
              class="text-sm text-center text-green-600 mt-2 ms-3 me-3"
              id="email-registration-success"
            >{{ success }}</p>

            <div class="mt-8 flex flex-row justify-center">
              <div class="flex">
                <input
                  id="terms-conditions"
                  name="termsAndconditions"
                  formControlName="termsAndconditions"
                  type="checkbox"
                  required
                  class="me-2 shrink-0 border-gray-200 rounded text-elysator-blue focus:ring-elysator-blue dark:bg-slate-900 dark:border-gray-700 dark:checked:bg-elysator-blue dark:checked:border-elysator-blue dark:focus:ring-offset-gray-800"
                >
                <label
                  for="terms-conditions"
                  class="max-w-md text-xs dark:text-white"
                >Ich habe die <a
                    class="text-elysator-blue decoration-2 hover:underline font-medium"
                    href="#"
                  >Teilnahmebedingungen</a> und <a
                    class="text-elysator-blue decoration-2 hover:underline font-medium"
                    href="#"
                  >Hinweise zum Datenschutz</a> gelesen und akzeptiere diese.</label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
