import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LicenseCode } from '../license-codes/license-code.service';

export interface User {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  email: string;
  name: string;
  role: Role;
  active: boolean;
  validated: boolean;
  validatedAt: Date;
  otpSentAt: Date;
  licenseCodes: LicenseCode[];
}

export interface MeasuredObject {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  userId: string;
  // measuredValues: MeasuredValue[];
}

export enum Role {
  USER = 'USER', ADMIN = 'ADMIN'
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  getMe(): Observable<User> {
    return this.http.get<User>(`${environment.apiBase}/user/me`);
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${environment.apiBase}/user/${id}`);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiBase}/user`);
  }

  deleteUser(id: string): Observable<User> {
    return this.http.delete<User>(`${environment.apiBase}/user/${id}`);
  }

  getMeasuredObjectsByUser(userId: string): Observable<MeasuredObject[]> {
    return this.http.get<MeasuredObject[]>(`${environment.apiBase}/measured-object/user/${userId}`);
  }
}
