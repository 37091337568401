<app-page-wrapper>
  <h1 class="block text-3xl mb-9 font-bold text-gray-800 sm:text-4xl lg:leading-tight">{{ 'legalNotice.title' | translate }}</h1>
  <div class="text-lg text-gray-800 mb-10 md:mb-16">
    <p class="mt-3 mb-5">{{ 'legalNotice.text' | translate }}</p>
    <div class="grid md:grid-cols-2">
      <div>
        <b>ELYSATOR Engineering GmbH</b><br />
        Rauheckstr. 20<br />
        74232 Abstatt<br /><br />
        {{ 'legalNotice.phone' | translate }}: <a
          href="tel:+49 7062 9795765"
          class="text-elysator-blue hover:underline"
        >+49 7062 9795765</a><br />
        {{ 'legalNotice.fax' | translate }}: +49 7062 9795767<br />
        {{ 'legalNotice.email' | translate }}: <a
          href="mailto:info&commat;elysator.de"
          class="text-elysator-blue hover:underline"
        >info&commat;elysator.de</a>
      </div>
      <div class="justify-start">
        <img
          src="assets/elysator_logo.png"
          class="w-full max-w-80 p-0 mt-8"
        />
      </div>
    </div>
  </div>
</app-page-wrapper>
