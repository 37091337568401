import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export interface EmailRegistration {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailRegistrationService {
  constructor(private http: HttpClient) { }

  register(email: string): Observable<EmailRegistration> {
    return this.http
      .post<EmailRegistration>(
        `${environment.apiBase}/email-registration`,
        { email: email }
      );
  }
}
