import { Component } from '@angular/core';
import { PageWrapperComponent } from '../page-wrapper/page-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-support-page',
  standalone: true,
  imports: [PageWrapperComponent, TranslateModule],
  templateUrl: './support-page.component.html',
  styleUrl: './support-page.component.scss'
})
export class SupportPageComponent {

}
