import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from './users/user.service';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit {

  constructor(private userService: UserService, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.userService.getMe().subscribe({
      next: (user) => {
        this.authService.currentUser$.next(user);
        localStorage.setItem('UID', user.id);
      },
      error: (error) => {
        this.authService.currentUser$.next(null);
        localStorage.removeItem('UID');
      },
    });
  }

  logout() {
    this.authService.logout().subscribe({
      next: (res) => {
        this.router.navigateByUrl('/login');
      },
      error: (err) => {
        console.log(err);
        this.router.navigateByUrl('/login');
      },
    });
  }
}
