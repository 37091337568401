import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';
import { LicenseCode, LicenseCodeService } from './license-code.service';

@Component({
  selector: 'app-license-codes-page',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule],
  templateUrl: './license-codes-page.component.html',
  styleUrl: './license-codes-page.component.scss',
})
export class LicenseCodesPageComponent {
  constructor(
    private licenseCodeService: LicenseCodeService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) { }

  public licenseCodes: LicenseCode[] = [];
  public licensesToCreate: number = 1;
  public licenseCodeId?: string;

  public isAdmin = false;

  public textFilter?: string;

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(user => {
      this.isAdmin = user?.role === 'ADMIN';
      this.reload();
    });
  }


  public createLicenseCodePdf(quantity: number) {
    if (this.isAdmin) {
      if (quantity < 1) {
        quantity = 1;
      } else if (quantity > 1000) {
        quantity = 1;
      }
      this.licenseCodeService.createLicenseCodePdf(quantity).subscribe({
        next: (data) => {
          this.loadData();
          if (quantity === 1) {
            this.toastr.success(`${quantity} Lizenz-Code erfolgreich erstellt`);
          } else {
            this.toastr.success(`${quantity} Lizenz-Codes erfolgreich erstellt`);
          }
          const fileURL = URL.createObjectURL(data);
          window.open(fileURL, '_blank');
          this.licensesToCreate = 1;
        },
        error: (error) => this.toastr.error('Lizenz-Codes konnten nicht erstellt werden', 'Fehler!'),
      });
    }
  }

  public deleteLicenseCode() {
    if (this.isAdmin && this.licenseCodeId) {
      this.licenseCodeService.deleteLicenseCode(this.licenseCodeId).subscribe({
        next: (licenseCode) => {
          this.toastr.success('Lizenz-Code erfolgreich gelöscht');
          this.licenseCodes = this.licenseCodes.filter(lc => lc.id !== licenseCode.id);
          this.licenseCodeId === undefined;
        },
        error: (error) => {
          this.toastr.error('Lizenz-Code konnte nicht gelöscht werden', 'Fehler!');
          this.licenseCodeId === undefined;
        },
      });
    }
  }

  private loadData() {
    if (this.isAdmin) {
      this.licenseCodeService.getLicenseCodes().subscribe({
        next: (licenseCodes) => {
          if (this.textFilter && this.textFilter.length > 2) {
            this.licenseCodes = licenseCodes.filter(lc => lc.code.toLowerCase().includes(this.textFilter!.toLowerCase())
              || (lc.userEmail && lc.userEmail.toLowerCase().includes(this.textFilter!.toLowerCase()))
            );
          } else {
            this.licenseCodes = licenseCodes;
          }
        },
        error: (error) => this.toastr.error('Lizenz-Codes konnten nicht erfolgreich geladen werden', 'Fehler!'),
      });
    }
  }

  public reload() {
    this.loadData();
  }

  public validateInput() {
    if (this.licensesToCreate < 1000) {
      this.licensesToCreate = 1000;
    } else if (this.licensesToCreate < 1) {
      this.licensesToCreate = 1;
    }
  }
}

