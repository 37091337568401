import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';
import { EmailRegistration } from '../../email-registration/email-registration.service';
import { EmailService } from './email.service';

@Component({
  selector: 'app-emails-page',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule],
  templateUrl: './emails-page.component.html',
  styleUrl: './emails-page.component.scss'
})
export class EmailsPageComponent {

  constructor(
    private emailService: EmailService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) { }

  public emails: EmailRegistration[] = [];
  public emailId?: string;

  public isAdmin = false;

  public textFilter?: string;

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(user => {
      this.isAdmin = user?.role === 'ADMIN';
      this.reload();
    });
  }

  public deleteEmail() {
    if (this.isAdmin && this.emailId) {
      this.emailService.deleteEmail(this.emailId).subscribe({
        next: (email) => {
          this.toastr.success('E-Mail Adresse erfolgreich gelöscht');
          this.emails = this.emails.filter(e => e.id !== email.id);
          this.emailId = undefined;
        },
        error: (error) => this.toastr.error('E-Mail Adresse konnte nicht gelöscht werden', 'Fehler!'),
      });
    }
  }

  private loadData() {
    if (this.isAdmin) {
      this.emailService.getEmails().subscribe({
        next: (emails) => {
          if (this.textFilter && this.textFilter.length > 2) {
            this.emails = emails.filter(e => e.email.toLowerCase().includes(this.textFilter!.toLowerCase()));
          } else {
            this.emails = emails;
          }
        },
        error: (error) => this.toastr.error('E-Mail Adressen konnten nicht erfolgreich geladen werden', 'Fehler!'),
      });
    }
  }

  public reload() {
    this.loadData();
  }
}

