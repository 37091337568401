<app-page-wrapper>
  <h1 class="block text-3xl mb-9 font-bold text-gray-800 sm:text-4xl lg:leading-tight">{{ 'support.title' | translate }}</h1>
  <div class="text-lg text-gray-800 mb-10 md:mb-16">
    <p class="mt-3 mb-10 md:mb-16">{{ 'support.text' | translate }}</p>
    <div class="grid md:grid-cols-2 gap-8 lg:gap-20">
      <div class="flex gap-3 items-center justify-start md:justify-center lg:justify-end">
        <a href="tel:+49 Ø7062 9795765"><svg
            class="bg-elysator-blue p-2 w-12 rounded-xl"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
          >
            <path
              d="M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z"
            />
          </svg></a>
        <a
          class="text-xl font-bold text-elysator-gray-dark hover:underline"
          href="tel:+49 7062 9795765"
        >+49 7062 9795765</a>
      </div>
      <div class="flex gap-3 items-center justify-start md:justify-center lg:justify-start">
        <a href="mailto:info@elysator.de"><svg
            class="bg-elysator-blue p-2 w-12 rounded-xl"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
          >
            <path
              d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"
            />
          </svg></a>
        <a
          class="text-xl text-elysator-gray-dark font-bold hover:underline"
          href="mailto:info@elysator.de"
        >INFO&commat;ELYSATOR.DE</a>
      </div>
    </div>
  </div>
  <div>
    <p class="text-xl font-bold">{{ 'support.licenseCodeTitle' | translate }}</p>
    <p class="mt-3 text-gray-800">{{ 'support.licenseCodeText1' | translate }}<br /><br />{{ 'support.licenseCodeText2' | translate }}</p>
  </div>
</app-page-wrapper>
