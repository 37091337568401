<app-page-wrapper>
  <h1 class="block text-3xl mb-6 font-bold text-gray-800 sm:text-4xl lg:leading-tight">{{ 'home.title' | translate }}</h1>
  <div class="grid lg:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-start">
    <div>
      <p class="mt-3 text-lg text-gray-800">{{ 'home.text1' | translate }}</p>
      <p class="mt-3 text-lg text-gray-800">{{ 'home.text2' | translate }}</p>
      <p class="mt-3 text-lg text-gray-800">
        <span class="font-bold">{{ 'home.measuredData.title' | translate }}</span><br />
      </p>


      <ul class="space-y-2 mt-4 text-lg text-gray-800">
        <li class="flex space-x-1">
          <svg
            class="flex-shrink-0 size-5 mt-1 text-elysator-blue"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span class="text-gray-800">{{ 'home.measuredData.phValue' | translate }}</span>
        </li>

        <li class="flex space-x-1">
          <svg
            class="flex-shrink-0 size-5 mt-1 text-elysator-blue"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span class="text-gray-800">{{ 'home.measuredData.conductivity' | translate }}</span>
        </li>

        <li class="flex space-x-1">
          <svg
            class="flex-shrink-0 size-5 mt-1 text-elysator-blue"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span class="text-gray-800">{{ 'home.measuredData.totalSaltContent' | translate }}</span>
        </li>

        <li class="flex space-x-1">
          <svg
            class="flex-shrink-0 size-5 mt-1 text-elysator-blue"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span class="text-gray-800">{{ 'home.measuredData.temperature' | translate }}</span>
        </li>
      </ul>

      <p class="mt-6 text-lg text-gray-800">{{ 'home.downloadApp' | translate }}</p>

      <div class="mt-7 gap-3 w-full flex justify-center sm:justify-start">
        <a
          href="https://apps.apple.com/de/app/elysator/id1673737988"
          target="_blank"
        >
          <img
            class="rounded-lg h-12 sm:h-14"
            [src]="'assets/store/' + ('appStoreImg' | translate)"
            alt="Play Store"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=de.elysator.app"
          target="_blank"
        >
          <img
            class="rounded-lg h-12 sm:h-14"
            [src]="'assets/store/' + ('playStoreImg' | translate)"
            alt="Play Store"
          />
        </a>
      </div>

      <p class="mt-6 text-lg text-gray-800">{{ 'home.licenseCode' | translate }}</p>
      <p class="mt-6 text-lg text-gray-800">{{ 'home.licenseCodeSupport' | translate }} <a
          href="#"
          class="text-elysator-blue underline"
          routerLink="/support"
        >{{ 'home.licenseCodeSupportLink' | translate }}</a>.</p>
    </div>
    <div>
      <div class="inline-block align-top">
        <img
          class="w-full"
          src="assets/elysator-app.jpg"
          alt="Elysator App"
        >
      </div>
      <div>
        <div class="mt-10 grid gap-8">
          <div class="flex gap-3 items-center justify-start">
            <a
              href="assets/pdf/ELY_2023_036B_Messgeraet-V2.pdf"
              target="_blank"
            >
              <img
                class="bg-elysator-red w-12 rounded-xl"
                width="24px"
                src="assets/icon_prospekt.png"
              /></a>
            <a
              class="text-xl font-bold text-elysator-gray-dark hover:underline"
              href="assets/pdf/ELY_2023_036B_Messgeraet-V2.pdf"
              target="_blank"
            >{{ 'home.flyer' | translate | uppercase }} (PDF)</a>
          </div>
          <div class="flex gap-3 items-center justify-start">
            <a
              href="assets/pdf/ELY_2023_031_Manual-EC-pH2035_highres.pdf"
              target="_blank"
            >
              <img
                class="bg-elysator-red w-12 rounded-xl"
                width="24px"
                src="assets/icon_anleitung.png"
              /></a>
            <a
              class="text-xl text-elysator-gray-dark font-bold  hover:underline"
              href="assets/pdf/ELY_2023_031_Manual-EC-pH2035_highres.pdf"
              target="_blank"
            >{{ 'home.manual' | translate | uppercase }} (PDF)</a>
          </div>
          <div class="flex gap-3 items-center justify-start">
            <a
              class="text-xl text-elysator-gray-dark font-bold hover:underline"
              href="assets/pdf/CE-Konformitaet-EC-pH2035-1.pdf"
              target="_blank"
            >
              <img
                class="bg-elysator-red w-12 rounded-xl"
                width="24px"
                src="assets/icon_ce.png"
              /></a>
            <a
              class="text-xl text-elysator-gray-dark font-bold hover:underline"
              href="assets/pdf/CE-Konformitaet-EC-pH2035-1.pdf"
              target="_blank"
            >{{ 'home.declaration' | translate | uppercase }} (PDF)</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-page-wrapper>
